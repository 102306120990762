import React from 'react';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import { Link } from 'gatsby';
import * as styles from './CatalogSection.module.css';

type ColorType = 'primary' | 'secondary';

type CatalogSectionProps = {
  title: string;
  subTitle: string;
  sectionId: string;
  colorType: ColorType;
  services: Service[];
};

type Service = {
  href: string;
  imageSrc: string;
  name: string;
  info: string;
};

const CatalogSection: React.FC<CatalogSectionProps> = ({
  title,
  subTitle,
  services,
  colorType,
  sectionId,
}) => {
  let SectionClass = styles.sectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    SectionClass = styles.sectionPrimary;
  } else if (colorType === 'secondary') {
    SectionClass = styles.sectionSecondary;
  }
  return (
    <ScrollAnimation>
      <section className={SectionClass} id={sectionId}>
        <div className={styles.catalogSectionInner}>
          <h2 className={styles.head}>
            <span className={styles.headText}>{title}</span>
            <span className={styles.headSubtext}>{subTitle}</span>
          </h2>
          <div className={styles.worksList}>
            {services.map((service, index) => (
              <Link key={index} className={styles.worksItem} to={service.href}>
                <div className={styles.worksImg}>
                  <img src={service.imageSrc} alt="works-sample-thumb" />
                </div>
                <p className={styles.worksName}>{service.name}</p>
                <p className={styles.worksInfo}>{service.info}</p>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default CatalogSection;
