// extracted by mini-css-extract-plugin
export var catalogSectionInner = "CatalogSection-module--catalogSectionInner--7144f";
export var head = "CatalogSection-module--head--b1069";
export var headSubtext = "CatalogSection-module--headSubtext--ce7d6";
export var headText = "CatalogSection-module--headText--6825c";
export var sectionPrimary = "CatalogSection-module--sectionPrimary--1a9d7";
export var sectionSecondary = "CatalogSection-module--sectionSecondary--18d98";
export var title = "CatalogSection-module--title--f8e49";
export var worksImg = "CatalogSection-module--worksImg--fda8d";
export var worksInfo = "CatalogSection-module--worksInfo--8172a";
export var worksItem = "CatalogSection-module--worksItem--03def";
export var worksList = "CatalogSection-module--worksList--910ed";
export var worksName = "CatalogSection-module--worksName--ec3f4";